import { Dialog } from '@headlessui/react';
import { FC, useEffect, useState } from 'react';
import Template from './Template';
import { useMarker } from '../context/MarkerContext';
import { SimpleLoader } from '../simpleLoader/SimpleLoader';

type Props = {
  isOpen: boolean;
  closeModal: (exit ?: boolean) => void;
  onContinue?: () => void;
};

const UnsavedChangesModal: FC<Props> = ({ isOpen, closeModal, onContinue }) => {
  const { markerArea, isLoadingUpdateImage } = useMarker();
  const [canContinue, setCanContinue] = useState<boolean>(false);

  const handleDiscardChanges = async () => {
    await markerArea.close();
    await closeModal();
    onContinue && onContinue();
  };

  const handleSaveMarkers = async () => {
    await markerArea.startRenderAndClose();
    setCanContinue(true);
  };

  useEffect(() => {
    if (!canContinue || isLoadingUpdateImage) return;
    closeModal();
    onContinue && onContinue();
  }, [isLoadingUpdateImage, canContinue]);

  return (
    <Template isOpen={isOpen} closeModal={closeModal}>
      <div className="inline-block DeleteModal w-full py-12 px-16 max-w-2xl  overflow-hidden text-left align-middle transition-all transform rounded-xl bg-white sm:mt-6 default-modal sm:mx-5">
        <div
          className="flex justify-end relative bottom-7 left-10 z-50 cursor-pointer"
          onClick={() => closeModal(true)}
        >
          <svg
            width={35}
            height={35}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
        <Dialog.Title
          as="h3"
          className="text-2xl text-gray-900 leading-8"
        ></Dialog.Title>
        <div className="flex">
          <div className="text-center">
            <h4 className="">{`You have unsaved changes. Would you like to save them before continuing?`}</h4>
          </div>
        </div>
        <div className="pt-6">
          {isLoadingUpdateImage ? (
            <SimpleLoader />
          ) : (
            <div className="flex space-x-3  flex-wrap justify-center items-baseline sm:justify-between">
              <button
                className="cancel-modal-btn"
                onClick={async () => {
                  await handleDiscardChanges();
                }}
              >
                Discard
              </button>
              <button
                className="unsaved-changes-modal-btn"
                onClick={async (event: any) => {
                  await handleSaveMarkers();
                }}
              >
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    </Template>
  );
};

export default UnsavedChangesModal;
