// src/App.tsx or a central component like Capture.tsx
import { lastPathState } from '@/state/appState';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

const PathTracker = () => {
  const location = useLocation();
  const setLastPath = useSetRecoilState(lastPathState);

  // Use a ref to store the previous location
  const prevPathRef = useRef<string | null>(null);

  useEffect(() => {
    // Update the Recoil state with the previous path
    setLastPath(prevPathRef.current || '/'); // Default to "/" if no previous path exists

    // Update the ref to store the current path
    prevPathRef.current = location.pathname;
  }, [location.pathname, setLastPath]);

  return null; // This component does not render anything
};

export default PathTracker;
