import React, { FC, MouseEvent, useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import {
  currentUserIDState,
  currentUserInfoState,
  isAnonymousState,
} from '@/state/userState';
import {
  currentCommentState,
  DEFAULT_CURRENT_COMMENT,
  isResolvedHidden,
  showCommentNumber,
} from '@/state/commentState';
import UserAvatar from '../UserAvatar';
import CaptureCommentDropdown from './CaptureCommentDropdown';
import CaptureCommentTextarea from './CaptureCommentTextarea';
import { firstCharToUpper } from '@/lib';
import { User } from 'firebase/auth';

import { useComment } from '@/hooks/useComment';
import ReplyButton from '../icon/ReplyButton';
import TickSmall from '../icon/TickSmall';
import DotsVerticalSmall from '../icon/DotsVerticalSmall';
import ArrowWithOutCirle from '../icon/ArrowWithOutCircle';
import ResolvedTick from '../icon/ResolveTick';
import Trash from '../icon/Trash';
import {
  captureBoardScrollYState,
  isDeletedModelOpen,
  emojiPickerState,
} from '@/state/uiState';
import DeleteModal from '../modal/DeleteModal';
import TrashMenu from '../icon/TrashMenu';
import Pencil from '../icon/Pecil';
import {
  canAddNewCommentState,
  canReplyState,
  canResolve,
  currentCaptureInfoState,
  getCurrentUserAccessType,
} from '@/state/captureState';
import NotificationDot from '../icon/NotificationDot';
import { markerPositionListState } from '@/state/markerState';
import { getUser } from '@/lib/firebase/firestore';
import Loading from '../Loading';
import AddEmojiIcon from '../icon/AddEmojiIcon';
import EmojiCounter from '../emoji/emojiCounter/EmojiCounter';
import EmojiMore from '../emoji/emojiMore/EmojiMore';
import AddEmojiWithoutBorderIcon from '../icon/AddEmojiWithoutBorderIcon';

const CaptureCommentReply: FC<
  Capture.Reply & {
    index: number;
    commentIndex: number;
    resolve?: boolean;
    isMain: boolean;
    totalReplies?: Number;
    toggleReplies: Function;
    toggleReplyTextArea: Function;
    createdAt: Number;
    commentReplyEmojies?: Capture.ReplyEmoji[];
  }
> = ({
  creator,
  content,
  videoHTML,
  updatedAt,
  index,
  commentIndex,
  resolve,
  isMain,
  totalReplies,
  toggleReplies,
  toggleReplyTextArea,
  createdAt,
  commentReplyEmojies,
  replyEmojies,
}): JSX.Element => {
    const currentUserInfo = useRecoilValue(currentUserInfoState) as User;
    const canReply = useRecoilValue(canReplyState);
    const canResolv = useRecoilValue(canResolve);
    const currentCaptureInfo = useRecoilValue(currentCaptureInfoState);
    const markerPositionList = useRecoilValue(markerPositionListState);
    const userID = useRecoilValue(currentUserIDState);
    const isAnonymous = useRecoilValue(isAnonymousState);
    const isResolveHidden = useRecoilValue(isResolvedHidden);
    const canCommentUser = useRecoilValue(canAddNewCommentState);

    const setCaptureBoardScrollY = useSetRecoilState(captureBoardScrollYState);
    const [showReply, setShowReply] = useRecoilState(showCommentNumber);
    const [currentComment, setCurrentComment] =
      useRecoilState(currentCommentState);
    const [emojiPickerConfig, setEmojiPickerConfig] =
      useRecoilState(emojiPickerState);

    const {
      setCaptureComment,
      addCommentEmoji,
      deleteCommentEmoji,
      addReplyEmoji,
      deleteReplyEmoji,
    } = useComment();

    const [showReplyToolTip, setShowReplyToolTip] = useState(false);
    const [showEmojiesToolTip, setShowEmojiesToolTip] = useState(false);
    const [isDropdownShow, setIsDropdownShow] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [showTooltip, setShowToolTip] = useState(false);
    const [isDeleteModelisOpen, setIsDeleteModelisOpen] = useState(false);
    const [isCommentLoaded, setIsCommentLoaded] = useState(false);
    let initialName = creator.email
      ? `${firstCharToUpper(creator.email)} (Guest)`
      : `${creator.displayName} (Guest)`;
    const [displayName, setDisplayName] = useState(initialName);
    const [showMoreEmojies, setShowMoreEmojies] = useState(false);

    const closeDeleteModal = () => {
      setIsDeleteModelisOpen(false);
      // console.log("DEFAULT_CURRENT_COMMENT ===", DEFAULT_CURRENT_COMMENT)
      setCurrentComment({ ...DEFAULT_CURRENT_COMMENT });
    };

    const toolbarReplyRef = useRef<HTMLDivElement | null>(null);
    const toolbarCommentRef = useRef<HTMLDivElement | null>(null);

    const Proceed = () => {
      //@ts-ignore
      setCaptureComment({ ...DEFAULT_CURRENT_COMMENT });
      setIsDeleteModelisOpen(false);
    };

    const handleClick = (event: MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      if (isDropdownShow) {
        setIsDropdownShow(false);
      } else {
        setShowReply((old) => ({
          commentIndex: commentIndex,
          showReply: old.commentIndex === commentIndex ? old.showReply : false,
        }));
        markerPositionList.length > commentIndex &&
          setCaptureBoardScrollY(markerPositionList[commentIndex].y);

        setCurrentComment({
          ...DEFAULT_CURRENT_COMMENT,
          isBeingModify: true,
          commentIndex: commentIndex,
          isPost: index === -1,
          replyIndex: index,
          isResolved: resolve,
          timestamp: dayjs().unix(),
          isMain: isMain,
          content: content,
        });
      }
    };

    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (
        currentComment.isBeingModify &&
        currentComment.commentIndex === commentIndex &&
        currentComment.replyIndex === index &&
        currentComment.modifyType !== 'EDIT' &&
        currentComment.modifyType !== 'TOGGLE_RESOLVE'
      ) {
        setIsDropdownShow(true);
      } else {
        setIsDropdownShow(false);
      }
      if (
        currentComment.modifyType === 'DELETE' &&
        currentComment.commentIndex === commentIndex &&
        currentComment.replyIndex === index
      ) {
        setIsDeleteModelisOpen(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentComment.timestamp]);

    const clickOutside = (e: Event) => {
      if (ref.current && ref?.current.contains(e.target as Node)) return false;
      if (isDropdownShow) return closeDropDown();
    };

    const closeDropDown = () => {
      setIsDropdownShow(false);
    };

    // let displayName = creator.email
    //   ? `${firstCharToUpper(creator.email)} (Guest)`
    //   : `${creator.displayName} (Guest)`;
    // if (!currentUserInfo.isAnonymous && creator.uid === currentUserInfo.uid) {
    //   setDisplayName(currentUserInfo.displayName || creator.displayName || '');
    // }

    const onMouseHover = (event: MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setShowToolTip(true);
    };

    const onMouseLeave = (event: MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setShowToolTip(false);
    };

    const onClickToggleResolve = async (event: MouseEvent<HTMLDivElement>) => {
      if (!canResolv) return false;
      event.preventDefault();
      event.stopPropagation();
      setShowToolTip(false);
      await setCurrentComment({
        ...DEFAULT_CURRENT_COMMENT,
        commentIndex,
        isBeingModify: true,
        isPost: index === -1,
        type: 'POST',
        modifyType: 'TOGGLE_RESOLVE',
        replyIndex: index,
        isResolved: resolve,
        isMain: isMain,
      });
    };

    const toggleReplyTextInput = (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      event.preventDefault();
      if (!canReply) return false;
      toggleReplyTextArea();
    };

    const togleReply = (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      event.preventDefault();

      if (showReply.commentIndex !== commentIndex) {
        setCurrentComment((old) => ({
          ...DEFAULT_CURRENT_COMMENT,
          commentIndex: commentIndex,
        }));
      }
      setShowReply((old) => ({
        commentIndex: commentIndex,
        showReply: true,
      }));
      // toggleReplies();
    };

    useEffect(() => {
      document.addEventListener('mousedown', clickOutside, true);
      return () => {
        document.removeEventListener('mousedown', clickOutside, true);
      };
    }, [isDropdownShow]);

    useEffect(() => {
      if (isDeleteModelisOpen === true) {
        setIsDropdownShow(false);
      }
    }, [isDeleteModelisOpen]);

    const getSubStringWithLastWord = (content: String, length: number) => {
      if (length > content.length) return content;
      let susbString = content.slice(0, length);
      let subStrWithfullWord = susbString.slice(0, susbString.lastIndexOf(' '));
      return subStrWithfullWord;
    };

    const increaseEmojiInString = (content: string | String) => {
      const emojiRegex =
        /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;
      const splitMessage = content.split(emojiRegex);

      const messageWithEmojiClass = splitMessage.map((part, index) => {
        if (part.match(emojiRegex)) {
          return (
            <span key={index} className="comment-emoji">
              {part}
            </span>
          );
        }

        return part;
      });
      return messageWithEmojiClass;
    };

    useEffect(() => {
      if (
        currentComment.modifyType === 'TOGGLE_RESOLVE' &&
        currentComment.commentIndex === commentIndex
      ) {
        //@ts-ignore

        setCaptureComment({ ...DEFAULT_CURRENT_COMMENT });
      }
    }, [currentComment]);

    useEffect(() => {
      if (creator?.uid) {
        getUser(creator.uid)
          .then((user) => {
            if (user) {
              setDisplayName(user.displayName);
              setIsCommentLoaded(true);
              return;
            }
          })
          .catch(() => {
            setIsCommentLoaded(true);
            return;
          });
      }
      setIsCommentLoaded(true);
    }, [creator])

    //Author Usman Sattar
    const deleteReply = (event: MouseEvent<HTMLDivElement>) => {
      //Here Will Change Modify Type and set Capture Comment if want to delete it will delete reply from current Comment selected Reply Index:
      //Delete Operation Must be performed When is Post is false means there must be reply index which will not -1.
      //Modify Type will explain which Operation will perform in hook ):
      // event.stopPropagation();
      // event.preventDefault();
      setCurrentComment((old) => ({
        ...DEFAULT_CURRENT_COMMENT,
        commentIndex,
        isPost: false,
        modifyType: 'DELETE',
        replyIndex: index,
        isBeingModify: true,
        timestamp: dayjs().unix(),
      }));
      setIsDeleteModelisOpen(true);
    };

    const updateReply = (event: MouseEvent<HTMLDivElement>) => {
      //Here Will Change Modify Type and set Capture Comment if want to delete it will delete reply from current Comment selected Reply Index:
      //Delete Operation Must be performed When is Post is false means there must be reply index which will not -1.
      //Modify Type will explain which Operation will perform in hook ):
      // event.stopPropagation();
      // event.preventDefault();
      setCurrentComment((old) => ({
        ...DEFAULT_CURRENT_COMMENT,
        commentIndex,
        isPost: false,
        modifyType: 'EDIT',
        replyIndex: index,
        isBeingModify: true,
        content: content,
        timestamp: dayjs().unix(),
      }));
      // setIsDeleteModelisOpen(true);
    };

    const resetEmojiConfig = () => {
      setEmojiPickerConfig({
        show: false,
        positioning: {
          targetElementRect: {
            height: 0,
            width: 0,
            x: 0,
            y: 0,
          } as DOMRect,
          offset: {
            x: 0,
            y: 0,
          },
        },
        handleAddIcon: () => { },
        handleHide: () => { },
      });
    };

    const handleAddEmojiToCommentClick = () => {
      if (emojiPickerConfig.show) {
        resetEmojiConfig();
      } else {
        setEmojiPickerConfig({
          show: true,
          positioning: {
            targetElementRect: toolbarCommentRef.current
              ? toolbarCommentRef.current.getBoundingClientRect()
              : ({
                height: 0,
                width: 0,
                x: 0,
                y: 0,
              } as DOMRect),
            offset: {
              x: 0,
              // y: commentIndex === 0 ? -35 : 405,
              y: 0,
            },
          },
          handleAddIcon: (emoji: string | number) => {
            if (typeof emoji === 'string') {
              addCommentEmoji({ emoji, commentIndex });
            } else if (typeof emoji === 'number') {
              addCommentEmoji({
                emoji: String.fromCodePoint(emoji),
                commentIndex,
              });
            }
          },
          handleHide: () => {
            resetEmojiConfig();
          },
        });
      }
    };

    const handleAddEmojiToReplyClick = () => {
      if (emojiPickerConfig.show) {
        resetEmojiConfig();
      } else {
        setEmojiPickerConfig({
          show: true,
          positioning: {
            targetElementRect: toolbarReplyRef.current
              ? toolbarReplyRef.current.getBoundingClientRect()
              : ({
                height: 0,
                width: 0,
                x: 0,
                y: 0,
              } as DOMRect),
            offset: {
              x: 216,
              // y: commentIndex === 0 ? -25 : 405,
              y: 0,
            },
          },
          handleAddIcon: (emoji: string | number) => {
            if (typeof emoji === 'string') {
              addReplyEmoji({
                replyCreator: creator.uid,
                emoji,
                commentIndex,
                createdAt,
                updatedAt,
              });
            } else if (typeof emoji === 'number') {
              addReplyEmoji({
                emoji: String.fromCodePoint(emoji),
                replyCreator: creator.uid,
                commentIndex,
                createdAt,
                updatedAt,
              });
            }
          },
          handleHide: () => {
            resetEmojiConfig();
          },
        });
      }
    };

    return isCommentLoaded ? (
      <div>
        {isMain === false ? (
          <div>
            <div className="flex">
              <div>
                <UserAvatar
                  uid={creator.uid}
                  height={24}
                  width={24}
                  customClass="user-avatar-custom"
                />
              </div>
              <div className="flex-1 w-32">
                <h4 className="name py-1 ml-2">
                  {displayName.substring(0, 10) + '...' || 'Anonymous'}
                  {/* {creator.uid === currentUserInfo.uid ? null : null} */}
                </h4>
              </div>
              <div className="ml-auto">
                <span className="reply-time mr-3 text-ellipsis overflow-hidden ...">
                  {dayjs.unix(updatedAt).fromNow().substring(0, 8)}...
                </span>
              </div>
              <div ref={toolbarReplyRef} className="ml-auto  my-2">
                {creator.uid === currentUserInfo.uid ? (
                  <div className="flex">
                    {canCommentUser && (
                      <span className="mr-1.5">
                        <div
                          className="cursor-pointer"
                          onClick={handleAddEmojiToReplyClick}
                        >
                          <AddEmojiWithoutBorderIcon width={12} height={12} />
                        </div>
                      </span>
                    )}
                    <span onClick={updateReply} title="edit">
                      <Pencil width={12} height={12} />
                    </span>
                    <span onClick={deleteReply} title="delete">
                      <TrashMenu width={12} height={12} />
                    </span>
                  </div>
                ) : (
                  <div className="flex">
                    {canCommentUser && (
                      <span className="mr-1.5">
                        <div
                          className="cursor-pointer"
                          onClick={handleAddEmojiToReplyClick}
                        >
                          <AddEmojiWithoutBorderIcon width={12} height={12} />
                        </div>
                      </span>
                    )}
                    <span onClick={toggleReplyTextInput}>
                      <ArrowWithOutCirle />
                    </span>
                    {creator.isAnonymous &&
                      currentCaptureInfo?.creator.uid === currentUserInfo.uid && (
                        <span onClick={deleteReply} title="delete">
                          <TrashMenu width={12} height={12} />
                        </span>
                      )}
                  </div>
                )}
              </div>
            </div>
            <p
              className="comment font-medium  py-2 flex items-center"
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {increaseEmojiInString(
                showMore ? content : getSubStringWithLastWord(content, 70)
              )}
              <span
                className="text-grey-100 cursor-pointer"
                onClick={() => {
                  setShowMore(!showMore);
                }}
              >
                {content.length < 70 ? null : showMore ? ' less' : ' ... more '}
                {/* setIsDeleteModelisOpen(false); */}
              </span>
            </p>

            {/* Emojies counter section */}
            <div className="emoji-badge-reply flex flex-row flex-wrap">
              {replyEmojies && (
                <>
                  {replyEmojies.map((emojiReplyData, index) => {
                    if (index > 3 && !showMoreEmojies) return;

                    return (
                      <EmojiCounter
                        key={`emoji counter component ${index}`}
                        emoji={emojiReplyData?.emoji}
                        count={emojiReplyData?.count}
                        creators={emojiReplyData?.creators}
                        emojiClickHandler={() => {
                          if (!canCommentUser) return;

                          const isCreatorAlredyExists =
                            emojiReplyData.creators.find(
                              (creatorData) => creatorData.uid === userID
                            );

                          // console.log(emojiReplyData.creators, userID);

                          if (isCreatorAlredyExists) {
                            console.log('delete');
                            deleteReplyEmoji({
                              emoji: emojiReplyData.emoji,
                              replyCreator: creator.uid,
                              userUID: userID,
                              createdAt,
                              updatedAt,
                              commentIndex,
                            });
                          } else {
                            console.log('add');

                            addReplyEmoji({
                              emoji: emojiReplyData.emoji,
                              replyCreator: userID,
                              createdAt,
                              updatedAt,
                              commentIndex,
                            });
                          }
                        }}
                      />
                    );
                  })}

                  {replyEmojies.length > 3 && (
                    <>
                      {!showMoreEmojies ? (
                        <div
                          className="emoji-badge"
                          onClick={() => setShowMoreEmojies(true)}
                        >
                          <p>Show more</p>
                        </div>
                      ) : (
                        <div
                          className="emoji-badge"
                          onClick={() => setShowMoreEmojies(false)}
                        >
                          <p>Show less</p>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        ) : (
          <div>
            <div className="flex">
              <div className="w-5/6 col-span-4">
                <div className="flex  items-center pb-2 pt-0 text-white">
                  <div className="flex flex-col px-0 ">
                    <UserAvatar
                      uid={creator.uid}
                      height={24}
                      width={24}
                      customClass="user-avatar-custom"
                    />
                  </div>
                  <div className="flex flex-col px-2">
                    <h4 className="name truncate max-w-36">
                      {displayName || 'Anonymous'}
                      {/* {creator.uid === currentUserInfo.uid ? null : null} */}
                    </h4>
                  </div>
                  <div className="content flex">
                    <h5
                      className="font-bold comment-number text-sm"
                      style={{ display: isMain ? '' : 'none' }}
                    >
                      #{commentIndex + 1}
                    </h5>
                  </div>
                  <div className="ml-1">{/* <NotificationDot /> */}</div>
                </div>
              </div>

              <div className="w-1/6 sm:flex sm:justify-end sm:pr-5">
                <div className="">
                  {((currentCaptureInfo?.creator.uid === currentUserInfo.uid &&
                    creator.isAnonymous) ||
                    currentUserInfo.uid === creator.uid) &&
                    !resolve && (
                      <div className="flex w-7 h-over:opacity-100 rounded-full hover:bg-gray-200 relative  main-user-dots  ">
                        <div
                          className={`w-4 h-4 hover:opacity-100 rounded-full hover:bg-gray-200 absolute tripleDotStyle left-2 cursor-pointer`}
                          onClick={handleClick}
                          tabIndex={0}
                          ref={ref}
                        >
                          <DotsVerticalSmall />
                          {isDropdownShow && (
                            <CaptureCommentDropdown
                              onlyDelete={
                                creator.isAnonymous &&
                                  creator.uid != currentUserInfo.uid
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>

            {/* Comment box content*/}
            <div className="flex flex-col">
              <p
                className={
                  currentComment.isBeingModify &&
                    currentComment.modifyType === 'EDIT'
                    ? 'hidden'
                    : 'comment font-medium'
                }
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {showMore
                  ? content
                  : getSubStringWithLastWord(content, 80).trimEnd()}
                <span
                  className="text-grey-100 cursor-pointer"
                  onClick={() => {
                    setShowMore(!showMore);
                  }}
                >
                  {content.length < 80 ? null : showMore ? ' less' : ' ... more'}
                </span>
              </p>

              {/* Loom player */}
              <div
                className="mt-2 rounded-full"
                dangerouslySetInnerHTML={{ __html: videoHTML }}
              ></div>
            </div>

            <hr className="my-3 whitespace-nowrap" />

            <div ref={toolbarCommentRef} className="flex">
              <div className="w-3/4 pt-1">
                <h1 className="comment-time">
                  {dayjs.unix(createdAt).fromNow()}
                </h1>
              </div>

              <div className="pt-1 whitespace-nowrap">
                <h5
                  className="comment-time text-right cursor-pointer"
                  onClick={togleReply}
                  style={{ display: isMain ? '' : 'none' }}
                >
                  {' '}
                  {totalReplies === 0 ? null : `${totalReplies} replies`}
                </h5>
              </div>
              <div
                className="flex justify-end relative mx-1.5"
                onClick={toggleReplyTextInput}
                onMouseEnter={(event) => {
                  if (!canReply) return false;
                  event.stopPropagation();
                  event.preventDefault();
                  setShowReplyToolTip(true);
                }}
                onMouseLeave={(event) => {
                  if (!canReply) return false;
                  event.stopPropagation();
                  event.preventDefault();
                  setShowReplyToolTip(false);
                }}
              >
                {isMain && <ReplyButton />}
                <div
                  className="tooltip-box top-7 border-2 border-white absolute whitespace-nowrap shadow-2xl font-normal tracking-wider text-white bg-black bg-opacity-70 px-2 py-1 text-xxxxs rounded-tl-md rounded-tr-2xl rounded-br-2xl rounded-bl-2xl"
                  style={{ display: showReplyToolTip ? '' : 'none' }}
                >
                  {showReplyToolTip ? 'Replies' : ''}
                </div>
              </div>

              {canCommentUser && (
                <div className="relative mr-1.5">
                  <div
                    className="cursor-pointer"
                    onClick={handleAddEmojiToCommentClick}
                    onMouseEnter={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      setShowEmojiesToolTip(true);
                    }}
                    onMouseLeave={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      setShowEmojiesToolTip(false);
                    }}
                  >
                    <AddEmojiIcon />
                    <div
                      className="tooltip-box top-7 right-0 border-2 border-white absolute whitespace-nowrap shadow-2xl font-normal tracking-wider text-white bg-black bg-opacity-70 px-2 py-1 text-xxxxs rounded-tl-md rounded-tr-2xl rounded-br-2xl rounded-bl-2xl"
                      style={{ display: showEmojiesToolTip ? '' : 'none' }}
                    >
                      {showEmojiesToolTip ? 'Emojies' : ''}
                    </div>
                  </div>
                </div>
              )}

              <div
                className="flex justify-end relative"
                onMouseEnter={(event) => {
                  if (!canResolv) return false;
                  onMouseHover(event);
                }}
                onMouseLeave={(event) => {
                  if (!canResolv) return false;
                  onMouseLeave(event);
                }}
                onClick={(event) => {
                  if (currentUserInfo.isAnonymous) return false;
                  onClickToggleResolve(event);
                }}
              >
                <div
                  className="tooltip-box top-7 border-2 border-white absolute whitespace-nowrap shadow-2xl font-normal tracking-wider text-white bg-black bg-opacity-70 px-2 py-1 text-xxxxs rounded-tl-md rounded-tr-2xl rounded-br-2xl rounded-bl-2xl"
                  style={{ display: showTooltip ? '' : 'none' }}
                >
                  {currentUserInfo.isAnonymous ? (
                    'Need to Sign In to mark resolve'
                  ) : (
                    <>{resolve ? 'Unresolved' : 'Resolve'}</>
                  )}
                </div>

                {currentUserInfo.isAnonymous ? (
                  resolve ? (
                    <ResolvedTick />
                  ) : (
                    <TickSmall />
                  )
                ) : showTooltip ? (
                  resolve ? (
                    <TickSmall />
                  ) : (
                    <ResolvedTick />
                  )
                ) : resolve ? (
                  <ResolvedTick />
                ) : (
                  <TickSmall />
                )}
              </div>
            </div>

            {/* Emojies counter section */}
            <div className="flex flex-row flex-wrap mt-2">
              {commentReplyEmojies && (
                <>
                  {commentReplyEmojies.map((emojiReplyData, index) => {
                    if (index > 3 && !showMoreEmojies) return;

                    return (
                      <EmojiCounter
                        key={`emoji counter component ${index}`}
                        emoji={emojiReplyData?.emoji}
                        count={emojiReplyData?.count}
                        creators={emojiReplyData?.creators}
                        emojiClickHandler={() => {
                          if (!canCommentUser) return;

                          const isCreatorAlredyExists =
                            emojiReplyData.creators.find(
                              (creatorData) => creatorData.uid === userID
                            );

                          if (isCreatorAlredyExists) {
                            console.log('delete');
                            deleteCommentEmoji({
                              emoji: emojiReplyData.emoji,
                              commentIndex,
                            });
                          } else {
                            console.log('add');

                            addCommentEmoji({
                              emoji: emojiReplyData.emoji,
                              commentIndex,
                            });
                          }
                        }}
                      />
                    );
                  })}

                  {commentReplyEmojies.length > 4 && (
                    // <EmojiMore
                    //   text={`${commentReplyEmojies.length - 5}`}
                    //   emojiesData={commentReplyEmojies.slice(
                    //     4,
                    //     commentReplyEmojies.length - 1
                    //   )}
                    // />

                    <>
                      {!showMoreEmojies ? (
                        <div
                          className="emoji-badge"
                          onClick={() => setShowMoreEmojies(true)}
                        >
                          <p>Show more</p>
                        </div>
                      ) : (
                        <div
                          className="emoji-badge"
                          onClick={() => setShowMoreEmojies(false)}
                        >
                          <p>Show less</p>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        )}

        {!resolve &&
          currentComment.isBeingModify &&
          currentComment.modifyType === 'EDIT' &&
          currentComment.commentIndex === commentIndex &&
          currentComment.replyIndex === index ? (
          <CaptureCommentTextarea isNew={false} />
        ) : null}

        <DeleteModal
          isDeleteModalOpen={isDeleteModelisOpen}
          closeModal={closeDeleteModal}
          Proceed={Proceed}
          deleteType={
            index != -1 && commentIndex != -1
              ? 'Reply'
              : 'comment. It will delete all replies'
          }
        />
      </div>
    ) : (
      <div className="  flex justify-center items-center">
        <div
          style={{ borderTopColor: 'transparent' }}
          className="w-4 h-4 border-1 border-black border-solid rounded-full animate-spin-fast"
        ></div>
      </div>
    );
  };

export default CaptureCommentReply;
