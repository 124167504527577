import { FC, memo, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { filter, uniqBy } from 'lodash';

import {
  currentCaptureInfoState,
  groupedByHostCaptureListSharedState,
  groupedByHostCaptureListState,
} from '@/state/captureState';

import CaptureItem from './CaptureItem';
import { currentUserIDState, currentUserInfoState } from '@/state/userState';
import { useGetUserSubscription } from '@/hooks/useGetUserSubscription';
import { projectListStateWithCapturesState } from '@/state/projectState';
import { pathState } from '@/state/appState';
import { useToolObject } from '@/hooks/tool/useToolObject';
import { useOrganization } from '@clerk/clerk-react';
import UnsavedChangesModal from '../modal/UnsavedChangesModal';
import { currentToolState } from '@/state/toolState';

type CaptureListProps = {
  sharedWithMe: boolean;
};

const CaptureList: FC<CaptureListProps> = ({ sharedWithMe }) => {
  const navigate = useNavigate();
  const {setToolStateAfterInsert} = useToolObject();
  const currentCaptureInfo = useRecoilValue(currentCaptureInfoState);
  const groupedByHostCaptureList = useRecoilValue(
    !sharedWithMe
      ? groupedByHostCaptureListState
      : groupedByHostCaptureListSharedState
  );
  const currentTool = useRecoilValue(currentToolState);
  const currentUserInfo = useRecoilValue(currentUserInfoState)
  const currentUserID = useRecoilValue(currentUserIDState);
  const projectListStateWithCaptures = useRecoilValue(
    projectListStateWithCapturesState
  );
  const { subscription } = useGetUserSubscription(currentUserID);
  const {cid} = useRecoilValue(pathState)
  const { organization } = useOrganization();

  const [currentCapturesList, setCurrentCapturesList] = useState<
    Capture.Info[]
  >([]);
  const setCurrentState = useSetRecoilState(currentCaptureInfoState);

  const [pendingCapture, setPendingCapture] = useState<Capture.Info | null>(null);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleCloseModal = () => {
    setPendingCapture(null);
    setIsOpenModal(false);
  }

  useEffect(() => {
    if (currentUserID == "") return; //not prepare userId to get plan type
    const currentCapturesWithHost = groupedByHostCaptureList.find((project) => {
      const index = project[1].findIndex(
        (capture) => capture.cid === currentCaptureInfo?.cid
      );
      return index !== -1;
    }); 
  
    if (currentCapturesWithHost) {
      let captureLs = uniqBy(currentCapturesWithHost[1], 'cid');
      captureLs = filter(captureLs, (capture) => !capture.isDeleted)
      if (subscription === 'free' && currentUserInfo?.tier !== "1" && !organization) {
        const currentDateUnix = Math.floor(new Date().getTime() / 1000);
        captureLs = captureLs.filter((item) => item.expiredAt && item.expiredAt > currentDateUnix);
      }

      setCurrentCapturesList(
        [...captureLs].sort((a, b) => a?.order! - b?.order! ?? false)
      );
    }
  }, [currentCaptureInfo, subscription, currentUserID, groupedByHostCaptureList, cid]);

  const continueToCapture = (targetCapture: Capture.Info) => {
    navigate(`${targetCapture.creator.uid}/${targetCapture.cid}`);
    setCurrentState(targetCapture)
    setToolStateAfterInsert()
    handleCloseModal();
  }

  const handleCaptureChoose = (targetCapture: Capture.Info) => {
    if (
      hasUnsavedChanges()
    ) {
      setPendingCapture(targetCapture);
      setIsOpenModal(true);
    } else {
      continueToCapture(targetCapture);
    }
  };
  
  const hasUnsavedChanges = () => {
    return currentTool === "ANNOTATE";
  };

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if(hasUnsavedChanges()){
        event.preventDefault(); 
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [currentTool]);

  return (
    <div id="sidebar-capture-list" className="overflow-y-auto max-h-70%">
      {currentCapturesList.map((capture, captureIndex) => {
        return (
            <CaptureItem
              key={`capture ${captureIndex}`}
              capture={capture}
              sequenceNumber={captureIndex}
              onChoose={handleCaptureChoose}
            />
        );
      })}
      {isOpenModal && pendingCapture && (
          <UnsavedChangesModal
            isOpen={isOpenModal}
            closeModal={handleCloseModal}
            onContinue={() => continueToCapture(pendingCapture)}
          />
        )}
    </div>
  );
};

export default memo(CaptureList);
