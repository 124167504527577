import { MutableRefObject } from 'react';
import _, { map } from 'lodash';
import { atom, selector } from 'recoil';
import { tempMarkerState } from './markerState';
import { currentUserIDState } from './userState';
import { Project } from 'types/project';

export const isGroupIdVerifiedState = atom<boolean>({
  key: 'IsGroupIdVerified',
  default: false,
});

export const isDownloadState = atom<boolean>({
  key: 'isDownloadState',
  default: false,
});

export const captureRefState =
  atom<MutableRefObject<HTMLDivElement | null> | null>({
    key: 'captureRefState',
    default: null,
  });

export const isParamCaptureImageState = atom<any>({
  key: 'isParamCaptureImage',
  default: {},
});

export const captureIsPublicGroupState = atom<boolean>({
  key: 'CaptureIsPublicGroup',
  default: false,
});

export const captureIsPublicState = atom<boolean>({
  key: 'CaptureIsPublic',
  default: true,
});

export const publicCaptureListState = atom<Capture.Info[]>({
  key: 'PublicCaptureList',
  default: [],
});

export const captureListState = atom<Capture.Info[]>({
  key: 'CaptureList',
  default: [],
});

export const capturesDeletedState = atom<Capture.Info[]>({
  key: 'CapturesDeleted',
  default: [],
});

export const currentCaptureInfoState = atom<Capture.Info | undefined | null>({
  key: 'CurrentCaptureInfo',
  default: undefined,
});

export const currentCaptureInfoIndexState = atom<number>({
  key: 'CurrentCaptureInfoIndex',
  default: -1,
});

export const currentCaptureIsPdfState = selector<boolean>({
  key: 'CurrentCaptureIsPdf',
  get: ({ get }) => {
    return get(currentCaptureInfoState)?.type === 'pdf';
  },
});

export const setCurrentCaptureInfoState = selector<
  Capture.Info | null | undefined
>({
  key: 'SetCurrentCaptureInfo',
  get: ({ get }) => {
    return get(currentCaptureInfoState);
  },
  set: ({ set, get }, value) => {
    if (!value) {
      set(currentCaptureInfoState, null);
    } else {
      const newValue = value as Capture.Info;
      set(currentCaptureInfoState, newValue);
      const captureIsPublic = get(captureIsPublicState);
      if (!captureIsPublic) {
        const list = get(captureListState);
        const index = _(list).findIndex({ cid: newValue.cid });
        const newList = [...list];
        newList.splice(index, 1, newValue);
        set(captureListState, newList);
      }
    }
  },
});

export const captureListAtHostState = selector<Capture.Info[] | []>({
  key: 'CaptureListAtHost',
  get: ({ get }) => {
    const isPublic = get(captureIsPublicState);
    if (isPublic) {
      return _.orderBy(get(publicCaptureListState), ['createdAt'], ['desc']);
    } else {
      const info = get(currentCaptureInfoState);
      if (!info) return [];
      const list = get(captureListState);
      const result = _<Capture.Info>(list)
        .filter({ group: info.group })
        .value();
      if (Array.isArray(result) && result.length > 0) {
        if (result[0].type === 'pdf') {
          return _.sortBy(result, ['order']);
        }
      }
      return _.orderBy(result, ['createdAt'], ['desc']);
    }
  },
});

export const captureCommentState = selector<Capture.Comment[] | []>({
  key: 'CaptureComment',
  get: ({ get }) => {
    const info = get(currentCaptureInfoState);
    const tempMarker = get(tempMarkerState);

    if (info && info.comments && tempMarker) {
      return [...info.comments, tempMarker];
    }
    if (info && info.comments && !tempMarker) {
      return [...info.comments];
    }
    if ((!info || !info.comments) && tempMarker) return [tempMarker];
    return [];
  },
});

export const countCaptureListState = selector<number>({
  key: 'CountCaptureList',
  get: ({ get }) => {
    return get(captureListState).length;
  },
});

export const groupedByHostCaptureListState = selector({
  key: 'GroupedByHostCaptureList',
  get: ({ get }) => {
    const list = map(get(captureListState), capture => ({ ...capture, expiresInXDays: capture?.expiredAt ? Math.round((capture.expiredAt - Math.floor(new Date().getTime() / 1000)) / 60 / 60 / 24) : null }));

    return _<Capture.Info>(list)
      .groupBy('group')
      .orderBy((group) => group[0].createdAt, ['desc'])
      .toPairs()
      .value();
  },
});

export const sortedCaptureCommentState = selector<Capture.SortedComment[]>({
  key: 'SortedCaptureComment',
  get: ({ get }) => {
    const comments = get(captureCommentState);
    return _(comments.map((item, index) => ({ ...item, index })))
      .orderBy(['createdAt'], ['asc'])
      .value();
  },
});
export const canCommentState = atom<boolean>({
  key: 'CanComment',
  default: false,
});
export const currentCaptureShareInvitListState = atom<Capture.ShareModel[]>({
  key: 'CurrentCaptureShareInvitList',
  default: [],
});
export const currentSelectedInviteShareModel = atom<
  Capture.ShareModel | undefined
>({
  key: 'currentSelectedInviteShareModel',
  default: undefined,
});
export const isNameUpdating = atom<boolean>({
  key: 'IsNameUpdating',
  default: false,
});
export const captureListSharedState = atom<Capture.Info[]>({
  key: 'CaptureListShare',
  default: [],
});
export const captureListSharedAtHostState = selector<Capture.Info[] | []>({
  key: 'CaptureListSharedAtHost',
  get: ({ get }) => {
    const info = get(currentCaptureInfoState);
    if (!info) return [];
    const list = get(captureListSharedState);
    const result = _<Capture.Info>(list).filter({ group: info.group }).value();
    if (Array.isArray(result) && result.length > 0) {
      if (result[0].type === 'pdf') {
        return _.sortBy(result, ['order']);
      }
    }
    return _.orderBy(result, ['createdAt'], ['desc']);
    // }
  },
});
export const countCaptureListSharedState = selector<number>({
  key: 'CountCaptureListShare',
  get: ({ get }) => {
    return get(captureListSharedState).length;
  },
});
export const groupedByHostCaptureListSharedState = selector({
  key: 'GroupedByHostCaptureListShared',
  get: ({ get }) => {
    let list = get(captureListSharedState);
    list = list.filter(capture => capture.hasOwnProperty('isDeleted') && !capture.isDeleted)
    return _<Capture.Info>(list)
      .orderBy(['createdAt'], ['desc'])
      .groupBy('group')
      .orderBy((group) => group[0].createdAt, ['desc'])
      .toPairs()
      .value();
  },
});
export const shareListWithRoles = atom<Capture.ShareModel[]>({
  key: 'shareListWithRoles',
  default: [],
});
export const currentUserRoleForCaptureState = selector<{
  Role?: Capture.RoleAssigned;
  isGroupShare?: boolean;
  InviteStatus?: Capture.InvitationStatus;
}>({
  key: 'currentUserRoleForCapture',
  get: ({ get }) => {
    let sharedList = get(shareListWithRoles);
    if (!sharedList)
      return {
        Role: 'ILLEGAL',
        isGroupShare: false,
        InviteStatus: 'NOTALLOWED',
      };
    let currentCaptureInfo = get(currentCaptureInfoState);
    if (currentCaptureInfo === null) return {};
    let currentUserID = get(currentUserIDState);
    if (currentUserID === currentCaptureInfo?.creator.uid)
      return { Role: 'ADMIN', isGroupShare: true, InviteStatus: 'ACCEPTED' };
    if (!sharedList || sharedList.length === 0)
      if (!currentCaptureInfo?.publicAccess) {
        return {
          Role: 'ILLEGAL',
          isGroupShare: false,
          InviteStatus: 'NOTALLOWED',
        };
      } else {
        return {
          Role: 'ILLEGAL',
          isGroupShare: false,
          InviteStatus: 'NOTALLOWED',
        };
      }

    let found = _.filter(sharedList, {
      cid: currentCaptureInfo?.cid,
      uid: currentCaptureInfo?.creator.uid,
    });
    if (found.length === 0) {
      let foundGroup = _.filter(sharedList, {
        groupName: currentCaptureInfo?.group,
        isGroupShare: true,
        uid: currentCaptureInfo?.creator.uid,
      });
      if (foundGroup.length === 0) {
        return {
          Role: 'ILLEGAL',
          isGroupShare: false,
          InviteStatus: 'NOTALLOWED',
        };
      } else {
        found = foundGroup;
      }
    }
    if (found[0].IniviteStatus === 'REQUESTED') {
      if (!currentCaptureInfo?.publicAccess) {
        return {
          Role: 'VIEW',
          isGroupShare: false,
          InviteStatus: 'NOTALLOWED',
        };
      }
      return {
        Role: 'ILLEGAL',
        isGroupShare: false,
        InviteStatus: 'REQUESTED',
      };
    }
    // console.log(`Role Data Found Shared With Me ${JSON.stringify(found[0])}`);
    return {
      Role: found[0]?.Role,
      isGroupShare: found[0]?.isGroupShare,
      InviteStatus: found[0].IniviteStatus,
    };
  },
});

export const currentCaptureIsPrivateState = atom<boolean>({
  key: 'currentCaptureIsPrivate',
  default: false,
});
export const canAddNewCommentState = selector<boolean>({
  key: 'canAddNewComment',
  get: ({ get }) => {
    const currentUserRole = get(currentUserRoleForCaptureState);
    const canCommentAnyOne = get(canCommentState);
    if (
      currentUserRole.Role === 'ADMIN' ||
      canCommentAnyOne ||
      currentUserRole.Role === 'EDIT'
    )
      return true;
    if (currentUserRole.Role === 'VIEW' || currentUserRole.Role === 'ILLEGAL')
      return false;

    return false;
  },
});
export const canReplyState = selector<boolean>({
  key: 'canReply',
  get: ({ get }) => {
    const currentUserRole = get(currentUserRoleForCaptureState);
    const canCommentAnyOne = get(canCommentState);

    if (
      currentUserRole.Role === 'ADMIN' ||
      canCommentAnyOne ||
      currentUserRole.Role === 'EDIT'
    )
      return true;
    if (currentUserRole.Role === 'VIEW' || currentUserRole.Role === 'ILLEGAL')
      return false;

    return false;
  },
});
export const canResolve = selector<boolean>({
  key: 'canResolve',
  get: ({ get }) => {
    const currentUserRole = get(currentUserRoleForCaptureState);
    if (currentUserRole.Role === 'ADMIN' || currentUserRole.Role === 'EDIT')
      return true;
    return false;
  },
});
export const canDeleteCapture = selector<boolean>({
  key: 'canDeleteCapture',
  get: ({ get }) => {
    const currentUserRole = get(currentUserRoleForCaptureState);
    if (currentUserRole.Role === 'ADMIN') return true;
    if (currentUserRole.Role === 'VIEW' || currentUserRole.Role === 'ILLEGAL')
      return false;

    return false;
  },
});
export const canDeleteProject = selector<boolean>({
  key: 'canDeleteProject',
  get: ({ get }) => {
    const currentUserRole = get(currentUserRoleForCaptureState);
    if (currentUserRole.Role === 'ADMIN') return true;

    return false;
  },
});
export const canShareCaptureLink = selector<boolean>({
  key: 'canShareCaptureLink',
  get: ({ get }) => {
    const currentUserRole = get(currentUserRoleForCaptureState);
    if (
      currentUserRole.Role === 'ADMIN' ||
      currentUserRole.Role === 'VIEW' ||
      currentUserRole.Role === 'EDIT'
    )
      return true;
    return false;
  },
});
export const canShareProjectLink = selector<boolean>({
  key: 'canShareProjectLink',
  get: ({ get }) => {
    const currentUserRole = get(currentUserRoleForCaptureState);

    if (currentUserRole.Role === 'ADMIN') return true;
    if (
      (currentUserRole.Role === 'EDIT' || currentUserRole.Role === 'VIEW') &&
      currentUserRole.isGroupShare
    )
      return true;

    return false;
  },
});
export const getCurrentUserAccessType = selector<Capture.ACCESSTYPE>({
  key: 'getCurrentUserAccessType',
  get: ({ get }) => {
    const currentUserRole = get(currentUserRoleForCaptureState);
    if (currentUserRole.Role === 'ADMIN') return 'CAPTURE-OWNER';
    if (currentUserRole.Role === 'VIEW' && !currentUserRole.isGroupShare)
      return 'VIEW-CAPUTRE-ACCESS';
    if (currentUserRole.Role === 'VIEW' && currentUserRole.isGroupShare)
      return 'VIEW-PROJECT-ACCESS';
    if (currentUserRole.Role === 'EDIT' && !currentUserRole.isGroupShare)
      return 'EDIT-CAPUTRE-ACCESS';
    if (currentUserRole.Role === 'EDIT' && currentUserRole.isGroupShare)
      return 'EDIT-PROJECT-ACCESS';
    return 'VIEW-CAPUTRE-ACCESS';
  },
});
export const addingNewCaptureState = atom<boolean>({
  key: 'addingNew',
  default: false,
});
export const currentCaptureCreatorUpdatedInfoState = atom<
  Capture.Creator | undefined
>({
  key: 'currentCaptureCreateUpdatedInfo',
  default: undefined,
});

export const isProjectOpen = atom<{
  open: boolean;
  gid: string;
  projectInfo: Partial<Project>;
  captureCount: number;
}>({
  key: 'isProjectOpen',
  default: { open: false, gid: '', projectInfo: {}, captureCount: 0 },
});

export const isLoadingThumbnailState = atom<boolean>({
  key: 'isLoadingThumbnail',
  default: false,
});

export const isLoadingThumbnailCidState = atom<string>({
  key: 'isLoadingThumbnailCid',
  default: "false",
});

export const imgRefState = atom<HTMLImageElement | null>({
  key: 'imgRef',
  default: null,
});