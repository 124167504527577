import {
  ColorPickerPanel,
  RectangularBoxMarkerBase,
  SvgHelper,
} from 'markerjs2';
import * as markerjs2 from 'markerjs2';

class CustomRenderer {
  /**
   * Whether the image should be rendered at the original (natural) target image size.
   */
  naturalSize = false;
  /**
   * Rendered image type (`image/png`, `image/jpeg`, etc.).
   */
  imageType = 'image/png';
  /**
   * For formats that support it, specifies rendering quality.
   *
   * In the case of `image/jpeg` you can specify a value between 0 and 1 (lowest to highest quality).
   *
   * @type {number} - image rendering quality (0..1)
   */
  imageQuality;
  /**
   * When set to true, only the marker layer without the original image will be rendered.
   */
  markersOnly = false;

  /**
   * When set and {@linkcode naturalSize} is `false` sets the width of the rendered image.
   *
   * Both `width` and `height` have to be set for this to take effect.
   */
  width;
  /**
   * When set and {@linkcode naturalSize} is `false` sets the height of the rendered image.
   *
   * Both `width` and `height` have to be set for this to take effect.
   */
  height;

  /**
   * Initiates rendering of the result image and returns a promise which when resolved
   * contains a data URL for the rendered image.
   *
   * @param target - target (underlying original) image
   * @param markerImage - marker layer
   */
  rasterize(target, markerImage, targetCanvas) {
    console.log("target ::", target);
    console.log("markerImage ::", markerImage);
    console.log("targetCanvas ::", targetCanvas);

    return new Promise((resolve) => {
      const canvas = targetCanvas !== undefined ? targetCanvas : document.createElement('canvas');

      if (target === null) {
        this.markersOnly = true;
        this.naturalSize = false;
      }

      const markerImageCopy = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      markerImageCopy.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
      markerImageCopy.setAttribute('width', markerImage.width.baseVal.valueAsString);
      markerImageCopy.setAttribute('height', markerImage.height.baseVal.valueAsString);
      markerImageCopy.setAttribute(
        'viewBox',
        '0 0 ' + markerImage.viewBox.baseVal.width.toString() + ' ' + markerImage.viewBox.baseVal.height.toString()
      );
      markerImageCopy.innerHTML = markerImage.innerHTML;

      function extractForeignObjectAttributes(svgElement) {
        const result = [];
        const gElements = svgElement.querySelectorAll('g');

        gElements.forEach((g) => {
          const foreignObject = g.querySelector('foreignObject');
          if (foreignObject) {
            const width = foreignObject.getAttribute('width');
            const height = foreignObject.getAttribute('height');
            const styleString = g.getAttribute('style');

            let translateX = 0,
              translateY = 0;

            if (styleString) {
              const regex = /translate\(([^,]+)px,\s*([^)]+)px\)/;
              const matches = styleString.match(regex);
              if (matches) {
                translateX = parseFloat(matches[1]);
                translateY = parseFloat(matches[2]);
              }
            }

            // Store the results
            result.push({
              width: parseFloat(width),
              height: parseFloat(height),
              translateX,
              translateY,
            });
          }
        });

        return result.filter(item => item.translateX !== 0 || item.translateY !== 0);
      }

      const foreignObjectData = extractForeignObjectAttributes(markerImageCopy);
      console.log(foreignObjectData);

      // Set natural size or custom size for the SVG
      if (this.naturalSize === true) {
        markerImageCopy.width.baseVal.value = target.naturalWidth;
        markerImageCopy.height.baseVal.value = target.naturalHeight;
      } else if (this.width !== undefined && this.height !== undefined) {
        markerImageCopy.width.baseVal.value = this.width;
        markerImageCopy.height.baseVal.value = this.height;
      }

      console.log("================");
      console.log("target.naturalWidth ::", target.naturalWidth);
      console.log("target.naturalHeight ::", target.naturalHeight);
      console.log("target image width ::", target.width);
      console.log("target image height ::", target.height);
      console.log("markerImageCopy.width.baseVal.value ::", markerImageCopy.width.baseVal.value);
      console.log("markerImageCopy.height.baseVal.value ::", markerImageCopy.height.baseVal.value);
      console.log("markerImage width ::", markerImage.width.baseVal.value);
      console.log("markerImage height ::", markerImage.height.baseVal.value);
      console.log("================");
  
      let scaleX = target ? target.naturalWidth / markerImage.width.baseVal.value : 1;
      let scaleY = target ? target.naturalHeight / markerImage.height.baseVal.value : 1;

      canvas.width = target ? target.naturalWidth : markerImageCopy.width.baseVal.value;
      canvas.height = target ? target.naturalHeight : markerImageCopy.height.baseVal.value;

      const ctx = canvas.getContext('2d');

      if (this.markersOnly !== true && target) {
        ctx.drawImage(target, 0, 0, canvas.width, canvas.height);
      }

      const blurCanvas = document.createElement('canvas');
      blurCanvas.width = canvas.width;
      blurCanvas.height = canvas.height;
      const blurCtx = blurCanvas.getContext('2d');

      // Apply blur effect to the entire canvas
      blurCtx.filter = 'blur(6px)';
      blurCtx.drawImage(canvas, 0, 0, canvas.width, canvas.height);

      // Draw blurred areas
      foreignObjectData.forEach((obj) => {
        ctx.drawImage(
          blurCanvas,
          obj.translateX * scaleX,
          obj.translateY * scaleY,
          obj.width * scaleX,
          obj.height * scaleY,
          obj.translateX * scaleX,
          obj.translateY * scaleY,
          obj.width * scaleX,
          obj.height * scaleY
        );
      });

      // Draw markers on top
      const DOMURL = window.URL;
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');

      function buildSvgImageUrl(svg) {
        let b64 = Buffer.from(svg).toString('base64');
        return 'data:image/svg+xml;base64,' + b64;
      }

      const url = buildSvgImageUrl(markerImageCopy.outerHTML);
      img.onload = () => {
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        DOMURL.revokeObjectURL(url);

        const result = canvas.toDataURL(this.imageType, this.imageQuality);
        resolve(result);
      };

      img.src = url;
    });
  }
  
}

export class CustomMarkerArea extends markerjs2.MarkerArea {
  async render() {
    this.setCurrentMarker();

    const renderer = new CustomRenderer();
    renderer.naturalSize = this.renderAtNaturalSize;
    renderer.imageType = this.renderImageType;
    renderer.imageQuality = this.renderImageQuality;
    renderer.markersOnly = this.renderMarkersOnly;
    renderer.width = this.renderWidth;
    renderer.height = this.renderHeight;

    // workaround for an issue in Safari where FreeHand marker
    // is not rendered on the first try for some reason
    await renderer.rasterize(
      this.target instanceof HTMLImageElement ? this.target : null,
      this.markerImage,
      this.renderTarget
    );

    return await renderer.rasterize(
      this.target instanceof HTMLImageElement ? this.target : null,
      this.markerImage,
      this.renderTarget
    );
  }

  open() {
    this.setupResizeObserver();
    this.setEditingTarget();
    this.setTopLeft();
    this.initMarkerCanvas();
    this.initOverlay();
    this.attachEvents();
    if (this.settings.displayMode === 'popup') {
      this.onPopupTargetResize();
    }

    // if (!Activator.isLicensed) {
    //   // NOTE:
    //   // before removing this call please consider supporting marker.js
    //   // by visiting https://markerjs.com/ for details
    //   // thank you!
    //   this.addLogo();
    // }

    this._isOpen = true;
    this._isFocused = true;
  }
}

class SvgHelperForeignObject extends SvgHelper {
  static createForeignObject(width, height, attributes = []) {
    const foreignObject = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'foreignObject'
    );
    foreignObject.setAttribute('width', width);
    foreignObject.setAttribute('height', height);

    attributes.forEach(([name, value]) => {
      foreignObject.setAttribute(name, value);
    });

    return foreignObject;
  }
}

export class BlurMarker extends RectangularBoxMarkerBase {
  blurAmount = 5;
  foreignObject;

  constructor(container, overlayContainer, settings) {
    super(container, overlayContainer, settings);

    this.blurAmount = settings.blurAmount || this.blurAmount;

    this.createVisual = this.createVisual.bind(this);

    this.blurPanel = new ColorPickerPanel(
      'Blur amount',
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      this.blurAmount
    );
    this.blurPanel.onColorChanged = this.setBlurAmount.bind(this);

    this.divElement = document.createElement('div');
  }

  createVisual() {
    this.visual = SvgHelper.createGroup();

    // Create a transparent rectangle for interaction
    this.rectElement = SvgHelper.createRect(this.width, this.height, [
      ['fill', 'transparent'],
      ['stroke', 'transparent'],
      ['stroke-width', '0'],
    ]);
    this.visual.appendChild(this.rectElement);

    // Create foreignObject for blur effect
    this.foreignObject = SvgHelperForeignObject.createForeignObject(
      this.width,
      this.height,
      [['style', 'pointer-events: none']]
    );

    // Set styles for divElement (blur effect)
    this.divElement.style.width = '100%';
    this.divElement.style.height = '100%';
    this.divElement.style.backdropFilter = `blur(${this.blurAmount}px)`;
    this.divElement.style.pointerEvents = 'none';

    // Append the div into the foreignObject
    this.foreignObject.appendChild(this.divElement);

    // Append the foreignObject to the SVG group (this.visual)
    this.visual.appendChild(this.foreignObject);

    this.addMarkerVisualToContainer(this.visual);
  }

  setSize() {
    super.setSize();
    SvgHelper.setAttributes(this.rectElement, [
      ['width', `${this.width}`],
      ['height', `${this.height}`],
    ]);
    SvgHelper.setAttributes(this.foreignObject, [
      ['width', `${this.width}`],
      ['height', `${this.height}`],
    ]);
    SvgHelper.setAttributes(this.visual, [
      ['width', `${this.width}`],
      ['height', `${this.height}`],
    ]);
  }

  pointerDown(point, target) {
    super.pointerDown(point, target);
    if (this.state === 'new') {
      this.createVisual();
      this.moveVisual(point);
      this._state = 'creating';
    }
  }

  resize(point) {
    super.resize(point);
    this.setSize();
  }

  pointerUp(point) {
    super.pointerUp(point);
    this.setSize();
  }

  ownsTarget(el) {
    return (
      super.ownsTarget(el) || el === this.visual || this.visual.contains(el)
    );
  }

  setBlurAmount(amount) {
    this.blurAmount = amount;
    if (this.divElement) {
      this.divElement.style.backdropFilter = `blur(${this.blurAmount}px)`;
    }
  }

  // get toolboxPanels() {
  //   return [this.blurPanel];
  // }

  getState() {
    const result = Object.assign(
      {
        blurAmount: this.blurAmount,
      },
      super.getState()
    );
    result.typeName = BlurMarker.typeName;

    return result;
  }

  restoreState(state) {
    const blurState = state;
    this.blurAmount = blurState.blurAmount || this.blurAmount;

    this.createVisual();
    super.restoreState(state);
    this.setSize();
  }

  scale(scaleX, scaleY) {
    super.scale(scaleX, scaleY);
    this.setSize();
  }

  select() {
    this.container.style.cursor = 'move';
    this._isSelected = true;
    this.manipulationStartState = this.getState();

    // Hide Rotator Point
    const firstLine = this.controlBox.querySelector('line');
    if (firstLine) {
      firstLine.style.display = 'none';
    }

    const allGroups = this.controlBox.querySelectorAll('g');
    if (allGroups.length > 0) {
      const lastGroup = allGroups[allGroups.length - 1];
      lastGroup.style.display = 'none';
    }

    this.controlBox.style.display = '';
  }
}

BlurMarker.typeName = 'BlurMarker';
BlurMarker.title = 'Blur marker';
BlurMarker.icon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M192 464c-79.5 0-144-64.5-144-144c0-13 5.1-33.5 17-61.1c11.5-26.6 27.6-55.8 45.5-84.7c29-46.8 61-90.2 81.5-117c20.5 26.7 52.6 70.2 81.5 117c17.9 28.9 34 58 45.5 84.7c11.9 27.6 17 48.2 17 61.1c0 79.5-64.5 144-144 144zM0 320C0 426 86 512 192 512s192-86 192-192c0-91.2-130.2-262.3-166.6-308.3C211.4 4.2 202.5 0 192.9 0l-1.8 0c-9.6 0-18.5 4.2-24.5 11.7C130.2 57.7 0 228.8 0 320zm144-8c0-13.3-10.7-24-24-24s-24 10.7-24 24c0 57.4 46.6 104 104 104c13.3 0 24-10.7 24-24s-10.7-24-24-24c-30.9 0-56-25.1-56-56z"/></svg>`;
