import CaptureImage from './CaptureImage';
import CaptureComment from './CaptureComment';
import { responsiveDesignState } from '@/state/responsiveState';

import { useRecoilState, useRecoilValue } from 'recoil';

import { useGetCreationTime } from '@/hooks/captures';
import { currentCaptureInfoState } from '@/state/captureState';
import { useEffect, useRef } from 'react';
import toast from 'react-hot-toast';
import { ReactComponent as CircleWarning } from '@/assets/icons/CircleWarning.svg';
import { ReactComponent as Close } from '@/assets/icons/Close.svg';

import { userSubscriptionEnum } from '@/hooks/useGetUserSubscription';
import { currentUserIDState, currentUserInfoState } from '@/state/userState';
import { useUpgradePlan } from '@/hooks/plan/useUpgradePlan';
import { isAppLoadingState } from '@/state/appState';
import { useMarker } from '../context/MarkerContext';
import { useOrganization } from '@clerk/clerk-react';


function inViewport(element : any) {
  if (!element) return 0;
  
  const elH = element.offsetHeight;
  const H = window.innerHeight;
  const r = element.getBoundingClientRect();
  const t = r.top;
  const b = r.bottom;

  return Math.max(0, t > 0 ? Math.min(elH, H - t) : Math.min(b, H));
}

const CaptureInfo = () => {
  const { comments } = useRecoilValue(responsiveDesignState);

  const currentUserInfo = useRecoilValue(currentUserInfoState);
  const currentCaptureInfo = useRecoilValue(currentCaptureInfoState);
  const currentUserID = useRecoilValue(currentUserIDState);
  const [isAppLoading, setIsAppLoading] = useRecoilState(isAppLoadingState);

  const { isLoading: isLoadingUpgrade, upgradePlan } = useUpgradePlan();
  const captureInfoRef = useRef<HTMLDivElement | null>(null)
  const { setContextCaptureInfoHeight } = useMarker();
  const { organization } = useOrganization();

  const { expiresInXDays = null, cid = null, name = "" }: any = currentCaptureInfo;

  useEffect(() => {
    const handleResizeOrScroll = () => {
      if (captureInfoRef?.current) {
        const heightInView = inViewport(captureInfoRef.current);
        setContextCaptureInfoHeight(heightInView - 20);
      }
    };

    window.addEventListener("scroll", handleResizeOrScroll);
    window.addEventListener("resize", handleResizeOrScroll);

    // Initial call to set the height
    handleResizeOrScroll();

    return () => {
      window.removeEventListener("scroll", handleResizeOrScroll);
      window.removeEventListener("resize", handleResizeOrScroll);
    };
  }, [captureInfoRef]);

  useEffect(() => {
    if (isLoadingUpgrade) setIsAppLoading(true);
  }, [isLoadingUpgrade])

  const redirectToBilling = (e: any) => {
    e.stopPropagation()
    upgradePlan && upgradePlan('pro');
  };

  useEffect(() => {
    if (isAppLoading == false) {
      toast.dismiss();
      if (expiresInXDays < 8 && currentUserInfo?.plan === userSubscriptionEnum.free && currentUserInfo?.tier !== "1" && cid && !organization) {
        toast(<span className='flex items-center gap-5'><span>{`“${name}” expires ${(expiresInXDays ?? 0) > 1 ? `in ${expiresInXDays} days` : ((expiresInXDays ?? 0) === 1 ? "tommorow" : "today")}! Free Plan captures are stored for 30 days only.`}<br />{'Consider upgrading to save your work.'}<span className='cursor-pointer text-pink' onClick={redirectToBilling}>{' Upgrade now.'}</span></span>
          <Close className='cursor-pointer' onClick={() => toast.dismiss("capture-" + cid)} />
        </span>, {
          icon: <CircleWarning />,
          duration: 6000,
          id: "capture-" + cid,
        });
      }
    }

    return (() => {
      toast.dismiss();
    })
  }, [expiresInXDays, cid, isAppLoading]);

  return (
    <div ref={captureInfoRef} className="relative max-h-100% h-full flex flex-row gap-3 width-100 image-container items-start sm:flex-col custom-w custom-height sm:ml-0">
      {/* main capture content  */}
      <div
        className={`capture-container w-full h-full sm:w-full overflow-x-auto overflow-y-hidden ${comments && 'sm:hidden'
          }`}
      >
        <CaptureImage />
      </div>
      {/* comment container */}
      <CaptureComment />
    </div>
  );
};

export default CaptureInfo;
