import { atom } from 'recoil';

export const appState = atom<boolean>({
  key: 'IsReady',
  default: false,
});

export const pathState = atom({
  key: 'Path',
  default: { uid: '', cid: '', gid: '' },
});

export const lastPathState = atom<string | null>({
  key: 'lastPathState', // Unique key for Recoil
  default: null, // Default to the root path
});
export const hasAuthProblemState = atom<boolean>({
  key: 'HasAuthProblem',
  default: false,
});

export const isCheckExtensionState = atom<boolean>({
  key: 'IsCheckExtension',
  default: false,
});

export const isExtensionActive = atom<boolean>({
  key: 'IsExtensionActive',
  default: false,
});

export const isAppLoadingState = atom<boolean>({
  key: 'IsAppLoading',
  default: true,
});

export const isLoomSupportedState = atom<boolean>({
  key: 'isLoomSupported',
  default: true,
});

export const deletingExpiredCapturesState = atom<boolean>({
  key: 'deletingExpiredCaptures',
  default: false,
});

