import Button from '../Button';
import DownloadDesktopApp from '../icon/DownloadDesktopApp';

import desktopAppLogo from '../../assets/images/logo-desktop-app.png'

const DesktopApp = () => {

    return (
        <div className="">
            <h1 className="text-xl font-medium">Instacap Desktop App</h1>
            <img className='w-16 h-16 mt-6' src={desktopAppLogo} />
            <h1 className="text-base font-medium mt-6">Instacap for Desktop is now available!</h1>
            <div className="flex mt-2">
                <p className="text-base text-gray-600 text-sm leading-6">
                    The Desktop app offers a dedicated space for seamless collaboration, enabling you to upload, comment, and annotate with ease. Enjoy an enhanced viewing experience for your screenshots and images, with the option to use the browser version for additional flexibility.
                </p>
            </div>
            <div className="flex mt-6">
                <a href={"https://dl.todesktop.com/240119pyie42p59"} download>
                    <Button
                        label={'Download'}
                        className="custom-btn px-6"
                    >
                        <span className="ml-2.5">
                            <DownloadDesktopApp />
                        </span>
                    </Button>
                </a>
            </div>
        </div>
    );
};

export default DesktopApp;
