import { memo, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { User } from 'firebase/auth';
import { currentUserInfoState, isAnonymousState } from '@/state/userState';
import { responsiveDesignState } from '@/state/responsiveState';
import { sidebarState } from '@/state/uiState';

import UserAvatar from '../UserAvatar';
import AvatarDropDown from './avatartDropDown';
import ArrowForward from '../icon/ArrowForward';
import DropdownIcon from '../icon/Dropdown.icon';
import AccountsDropdown from './AccountsDropdown';
import { useClickOutside } from '@/hooks/useClickOutside';
import { SignInButton, SignUpButton } from '@clerk/clerk-react';
import { CustomUserButton } from '../clerk';
import Check from '../icon/Check';
import { useLocation } from 'react-router-dom';
import UpgradePlan from '../dashboard/UpgradePlan';

const SidebarHeader = () => {
  const location = useLocation();

  const setIsSidebarShow = useSetRecoilState(sidebarState);
  const setResponsiveSidebar = useSetRecoilState(responsiveDesignState);

  const handleMobilenav = () => {
    setResponsiveSidebar({
      ...responsiveDesignState,
      responsive: true,
      sidebar: false,
      welcome: true,
    });
  };

  const handleClick = () => {
    setIsSidebarShow((isSideberShow) => !isSideberShow);
  };

  const currentUserInfo = useRecoilValue(currentUserInfoState) as User;
  const isSidebarShow = useRecoilValue(sidebarState);

  const [isDropdownShow, setIsDropdownShow] = useState(false);
  const [showAccountsList, setShowAccountsList] = useState(false);
  const isAnonymous = useRecoilValue(isAnonymousState);

  const avatarDropdownRef = useRef<HTMLDivElement>(null);
  const avatarDropdownListRef = useRef<HTMLDivElement>(null);
  const googleAccountDropdownRef = useRef<HTMLDivElement>(null);
  const googleAccountDropdownListRef = useRef<HTMLDivElement>(null);

  useClickOutside(avatarDropdownListRef, (e) => {
    if (!avatarDropdownRef.current?.contains(e.target as Node))
      setIsDropdownShow(false);
  });
  useClickOutside(googleAccountDropdownListRef, (e) => {
    if (!googleAccountDropdownRef.current?.contains(e.target as Node))
      setShowAccountsList(false);
  });

  if (!currentUserInfo) return null;

  return (
    <div className="relative sm:flex sm:justify-between sm:items-center ">
      {isAnonymous && isSidebarShow &&
        <div className='flex items-center flex-col mb-2'>
          <img
            src="/login-success.jpg"
            alt=""
            style={{ width: 200, height: 165 }}
          />
          <div className='mb-5 mt-5'>
            <div className="flex gap-2.5 mt-2">
              <Check color={'#6AD500'} />
              <p className="text-sm">{'Unlimited Projects & Captures'}</p>
            </div>
            <div className="flex gap-2.5 mt-2">
              <Check color={'#6AD500'} />
              <p className="text-sm">{'Full Commenting & Annotations'}</p>
            </div>
            <div className="flex gap-2.5 mt-2">
              <Check color={'#6AD500'} />
              <p className="text-sm">{'30 days Storage'}</p>
            </div>
          </div>
          <SignUpButton mode="modal" redirectUrl={location.pathname}>
            <button className="btn-green-outline border-100 custom-green-btn hover:opacity-100 share-modal-open">
              <span className="xsm:hidden">{"Sign Up for Free"}</span>
            </button>
          </SignUpButton>
          
        </div>
      }
      {/* {!isAnonymous && isSidebarShow && <div className='px-6'><UpgradePlan /></div>} */}

      <div className='px-6 mt-8 mb-6'> <hr /></div>

      <div className='relative'>
        <span
          className={`sidebar-open absolute -right-3 cursor-pointer z-10 ${isSidebarShow ? 'top-6 rotate-180 transform-gpu' : 'top-9'
            }`}
          onClick={handleClick}
        >
          <ArrowForward />
        </span>

        <div
          className={`flex gap-2 items-center relative ${!isSidebarShow ? 'mt-1 pb-6 px-6 pt-4' : 'mt-4 px-6 pb-6 '
            }`}
        >
          {isAnonymous ? (
            <div className="user-bg-hover p-2">
              <div
                ref={avatarDropdownRef}
                className={`rounded-full border-2 border-solid border-black overflow-hidden avatar-lg cursor-pointer ${!isSidebarShow && 'mx-auto mt-1 collapse-ava'
                  }`}
                onClick={() => {
                  setIsDropdownShow(!isDropdownShow);
                }}
              >
                <UserAvatar uid={currentUserInfo.uid} width={48} height={48} />
              </div>

              <div ref={avatarDropdownListRef}>
                <AvatarDropDown
                  setDropdownShow={setIsDropdownShow}
                  isDropdownShow={isDropdownShow && !currentUserInfo.isAnonymous}
                />
              </div>
            </div>
          ) :
            <CustomUserButton />
          }

          <div className={`flex-colum gap-0 pl-1 ${!isSidebarShow && 'hidden'}`}>
            <div className="sidebar-title flex flex-row">
              <p className="max-w-60 text-lg font-bold truncate ...">
                {currentUserInfo.displayName}
              </p>
              {/* <div
              ref={googleAccountDropdownRef}
              className="ml-2 cursor-pointer"
              onClick={() => setShowAccountsList((prev) => !prev)}
            >
              <DropdownIcon />
            </div> */}
            </div>
            <div className="flex items-center subtext-grey-xs pt-1 cursor-pointer">
              <span
                className="truncate ... max-w-60 user-email select-none xsm:hidden"
                onClick={() => {
                  setIsDropdownShow(!isDropdownShow);
                }}
              >
                {/* <div className="ml-4"> */}
                {isAnonymous && <SignUpButton mode="modal" redirectUrl={location.pathname} />}
                {/* </div> */}
                {!isAnonymous &&
                  (currentUserInfo.email || currentUserInfo.displayName)}
                &nbsp;
              </span>
            </div>
          </div>
        </div>
        <span className="hidden sm:block" onClick={handleMobilenav}>
          <img src="/Close.svg" />
        </span>
      </div>

      {/* <div ref={googleAccountDropdownListRef}>
        <AccountsDropdown show={showAccountsList} />
      </div> */}
    </div>
  );
};

export default memo(SidebarHeader);
