import { useRecoilValue } from 'recoil';
import cn from 'classnames';

import { useTool } from '@/hooks/tool/useTool';
import { useBoundingBox } from '@/hooks/tool/useBoundingBox';

import { currentUserInfoState } from '@/state/userState';

import { User } from '@firebase/auth';
import toast from 'react-hot-toast';
import { canCommentState, currentUserRoleForCaptureState } from '@/state/captureState';
import { useMarker } from '../context/MarkerContext';
import UnsavedChangesModal from '../modal/UnsavedChangesModal';
import { useEffect, useState } from 'react';
import { lastPathState } from '@/state/appState';
import { currentToolState } from '@/state/toolState';
import { useLocation, useNavigate } from 'react-router-dom';
// import { createBrowserHistory } from 'history';

const ToolMenu = () => {
  const { handleClickToolMenu, getListTool, currentTool } = useTool();
  const currentToolValue = useRecoilValue(currentToolState);
  const navigate = useNavigate();
  const location = useLocation();
  const lastPath = useRecoilValue(lastPathState);

  const { setIsBoundingBoxShow } = useBoundingBox();
  const currentUserInfo = useRecoilValue(currentUserInfoState) as User;
  const canComment = useRecoilValue(canCommentState);
  const { markerArea } = useMarker();
  const currentUserRoleForCapture = useRecoilValue(
    currentUserRoleForCaptureState
  );
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [fromToolMenu, setFromToolMenu] = useState(false);
  const [pendingTool, setPendingTool] = useState<string | null>(null);

  const handleCloseModal = (exit ?: boolean) => {
    setIsOpenModal(false);
    setFromToolMenu(false);

    !exit && pendingTool && applyToolChange(pendingTool)

    setPendingTool(null);
  }

  const hasUnsavedChanges = () => {
    return currentTool === "ANNOTATE";
  };

  const applyToolChange = (name: string) => {
    // @ts-ignore
    handleClickToolMenu(name);
    if (name === 'MOVE' || name === 'CHAT') {
      setIsBoundingBoxShow(false);
    } else {
      setIsBoundingBoxShow(true);
    }
  }

  useEffect(() => {
    if(currentTool !== "ANNOTATE") {
      // @ts-ignore
      // handleClickToolMenu(selectedTool);
      // setSelectedTool(null);
      return
    };
    const handlePopState = (event: PopStateEvent) => {
      if (hasUnsavedChanges()) {
        setIsOpenModal(true);
        setFromToolMenu(false);
  
        // Push the current path back to prevent navigation
        if (window.location.pathname !== location.pathname) {
          window.history.pushState(null, '', location.pathname);
        }
      } else {
        handleClickToolMenu("MOVE"); 
        // @ts-ignore
        // handleClickToolMenu(selectedTool); 
        // setSelectedTool(null); // Reset selected tool after action
      }
    };
  
    // Add a dummy state to the history to detect back button presses
    window.history.pushState(null, '', location.pathname);
  
    window.addEventListener('popstate', handlePopState);
  
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [currentTool]);


  const handleNavigateToLastPage = () => {
    lastPath && navigate(lastPath)
  }

  return (
    <>
      <ul
        className={`list-tool rounded-3xl bg-white`}
        style={{ boxShadow: '1px 1px 0px #000000' }}
      >
        {getListTool().map(({ Icon, name, description }, index) => {
          return (
            <li
              className={`list-tool-item flex items-center p-3 py-2 hover:bg-green ${
                name === currentTool && 'bg-green border-black border-1'
              } ${
                currentUserInfo.isAnonymous &&
                (canComment ? index > 1 : true) &&
                'opacity-30'
              }`}
              // style={{ height: name === currentTool ? 44 : 50 }}
              key={name}
              onClick={async (e) => {
                setFromToolMenu(true);
                // setSelectedTool(name);
                if(name === 'ANNOTATE' && currentUserRoleForCapture?.Role === "VIEW" ) return
                e.stopPropagation();
                if (currentUserInfo.isAnonymous) {
                  if (!(canComment && (name == 'CHAT' || name == 'MOVE'))) {
                    toast(
                      'You must be logged in to make edits to screen captures.'
                    );
                    return;
                  }
                }
                

                if (name !== 'ANNOTATE' && markerArea) {
                  if (currentTool === "ANNOTATE") {
                    setIsOpenModal(true);
                    setPendingTool(name);
                    return;
                  }  
                  // await markerArea.close();
                }

                applyToolChange(name)
                // handleClickToolMenu(name);
                // if (index < 2) {
                //   setIsBoundingBoxShow(false);
                // } else {
                //   setIsBoundingBoxShow(true);
                // }
              }}
            >
              <div className="flex items-center gap-2.5">
                <div className="relative fill-black">
                  <span className="tooltip-left-tooltip-text bg-black">
                    {description.toLowerCase()}
                  </span>
                  <Icon
                    classname={cn(
                      'stroke-current',
                      name === 'BLUR' && 'fill-current',
                      name === currentTool ? 'text-black' : 'text-gray-400'
                    )}
                    strokeColor={name === currentTool ? "#000000" : "#808080"}
                  />
                </div>
                <span
                  className="font-semibold"
                  style={{ color: name === currentTool ? '#000000' : '#808080' }}
                >
                  {description}
                </span>
              </div>
            </li>
          );
        })}
      </ul>
    
      <UnsavedChangesModal isOpen={isOpenModal} closeModal={handleCloseModal} 
      // @ts-ignore
        onContinue={!fromToolMenu ? handleNavigateToLastPage : undefined }
      />
    </>
  );
};

export default ToolMenu;
