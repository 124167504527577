import { useRecoilValue } from 'recoil';

import { currentCaptureInfoState } from '@/state/captureState';
import Pencil from '../icon/Pecil';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { currentToolState } from '@/state/toolState';
import UnsavedChangesModal from '../modal/UnsavedChangesModal';

const EditorCircle = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const currentTool = useRecoilValue(currentToolState);
  const navigate = useNavigate();
  const currentCaptureInfo = useRecoilValue(currentCaptureInfoState);

  const navigateToEditor = () => {
    navigate(
      `/editor/${currentCaptureInfo?.creator?.uid}/${currentCaptureInfo?.cid}`
    );
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const handleClick = () => {
    if (hasUnsavedChanges()) {
      setIsOpenModal(true);
    } else {
      navigateToEditor();
    }
  };

  const hasUnsavedChanges = () => {
    return currentTool === 'ANNOTATE';
  };

  return (
    <div
      className="flex flex-row cursor-default ml-3 cursor-pointer"
      onClick={handleClick}
    >
      <span className="owner-avatar inline-block h-9 w-9 rounded-full black-border items-center justify-center flex">
        <Pencil width={22} height={20} className={'ml-1'} />
      </span>

      {isOpenModal && (
        <UnsavedChangesModal
          isOpen={isOpenModal}
          closeModal={handleCloseModal}
          onContinue={navigateToEditor}
        />
      )}
    </div>
  );
};

export default EditorCircle;
