import { useCallback } from 'react';
import _ from 'lodash';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import {
  ArrowIcon,
  BlurIcon,
  CircleIcon,
  DrawIcon,
  TextIcon,
  CursorTextIcon,
  CursorArrowIcon,
  CursorDrawIcon,
  CursorCircleIcon,
  CursorBlurIcon,
  CoursorIcon,
} from '@/components/icon/tool';
import ChatIcon from '@/components/icon/tool/ChatIcon';
import {
  currentToolObjectIndexState,
  currentToolObjectState,
  currentToolState,
  isToolBarShowState,
} from '@/state/toolState';
import { useMarker } from '@/components/context/MarkerContext';
import { useMarkerTools } from './useMarkerTool';
import { currentCaptureInfoState } from '@/state/captureState';
import AnnotateIcon from '@/components/icon/tool/AnnotateIcon';
import EyeIcon from '@/components/icon/tool/EyeIcon';

export const useTool = () => {
  const {contextImgRef} = useMarker();
  const currentToolObject = useRecoilValue(currentToolObjectState);
  const [currentTool, setCurrentTool] = useRecoilState(currentToolState);
  const [isToolBarShow, setIsToolBarShow] = useRecoilState(isToolBarShowState);
  const { showMarkerArea } = useMarkerTools({imgRef: contextImgRef});
  const currentCaptureInfo = useRecoilValue(currentCaptureInfoState);

  const getListTool = useCallback(
    (): {
      Icon: (props: { classname: string, strokeColor ?: string }) => JSX.Element;
      name: Tool.ObjectType;
      description: string;
      CursorIcon: () => JSX.Element;
    }[] => [
      {
        Icon: EyeIcon,
        CursorIcon: CursorTextIcon,
        name: 'MOVE',
        description: "View"
      },
      {
        Icon: ChatIcon,
        CursorIcon: CursorTextIcon,
        name: 'CHAT',
        description: "Comment"
      },
      {
        Icon: AnnotateIcon,
        CursorIcon: CursorTextIcon,
        name: 'ANNOTATE',
        description: "Annotate"
      },
      // {
      //   Icon: TextIcon,
      //   CursorIcon: CursorTextIcon,
      //   name: 'TEXT',
      // },
      // {
      //   Icon: DrawIcon,
      //   CursorIcon: CursorDrawIcon,
      //   name: 'DRAW',
      // },
      // {
      //   Icon: ArrowIcon,
      //   CursorIcon: CursorArrowIcon,
      //   name: 'ARROW',
      // },
      // {
      //   Icon: CircleIcon,
      //   CursorIcon: CursorCircleIcon,
      //   name: 'CIRCLE',
      // },
      // {
      //   Icon: BlurIcon,
      //   CursorIcon: CursorBlurIcon,
      //   name: 'BLUR',
      // },
    ],
    []
  );

  const setNextCurrentTool = useRecoilCallback(({ snapshot }) => async () => {
    const currentTool = await snapshot.getLoadable(currentToolState).contents;

    const list = getListTool();
    const ln = list.length - 1;
    const index = _.findIndex(list, ['name', currentTool]);
    if (index === ln) {
      setCurrentTool(list[0].name);
    } else {
      setCurrentTool(list[index + 1].name);
    }
  });

  const handleClickToolMenu = useRecoilCallback(
    ({ reset, set }) =>
      (toolName: Tool.ObjectType) => {
        set(currentToolState, toolName);
        reset(currentToolObjectState);
        reset(currentToolObjectIndexState);

        console.log("toolName: " + toolName);
        if(toolName === "ANNOTATE"){
          if (contextImgRef?.current && currentCaptureInfo?.url) {
            // console.log("currentCaptureInfo?.url ====>>", currentCaptureInfo?.url)
            // console.log("imgRefFromRecoil ====>>", imgRefFromRecoil)

            // Reset the image source to the original URL before opening marker area
            contextImgRef.current.src = currentCaptureInfo?.url;
            
            // console.log("contextImgRef.current ===", contextImgRef.current)
            showMarkerArea(contextImgRef.current)
          }
          }
        }
  );

  return {
    getListTool,
    setNextCurrentTool,
    handleClickToolMenu,
    currentTool,
    currentToolObject,
    isToolBarShow,
    setIsToolBarShow,
  };
};
