import { useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import ReactPaginate from 'react-paginate';

import {
  addingNewCaptureState,
  captureListState,
  capturesDeletedState,
  groupedByHostCaptureListSharedState,
  groupedByHostCaptureListState,
  isNameUpdating,
  isProjectOpen,
} from '@/state/captureState';

import EmptyProject from '../../assets/images/mobview.png';
import CaptureItemDashboard from './CaptureItem';
import { uniqBy } from 'lodash';
import { expiredProjectState } from '@/state/projectState';
import { useGetUserSubscription } from '@/hooks/useGetUserSubscription';
import { currentUserIDState, currentUserInfoState } from '@/state/userState';
import { useOrganization } from '@clerk/clerk-react';

interface CaptureGridProps {
  sharedWithMe: boolean;
  hidePagination?: boolean;
  showRecent?: boolean;
  isExpired?: boolean;
  isDeleted?: boolean;
}

const CaptureGrid = (props: CaptureGridProps) => {
  const { isDeleted, isExpired } = props;
  const [currentItems, setCurrentItems] = useState<any>([]);
  const [pageCount, setPageCount] = useState(1);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const currentUserID = useRecoilValue(currentUserIDState);
  const currentUserInfo = useRecoilValue(currentUserInfoState);

  const groupedByHostCaptureList = useRecoilValue(
    !props.sharedWithMe
      ? groupedByHostCaptureListState
      : groupedByHostCaptureListSharedState
  );
  const [addingNew, setAddingNew] = useRecoilState(addingNewCaptureState);
  const currentProject = useRecoilValue(isProjectOpen);
  const captureListArray = useRecoilValue(captureListState);
  const capturesDeleted = useRecoilValue(capturesDeletedState);

  const sidBarScrollRef = useRef<HTMLDivElement | null>(null);
  const { subscription } = useGetUserSubscription(currentUserID);
  const { organization } = useOrganization();

  const captureList = useMemo(() => {
    const currentDateUnix = Math.floor(new Date().getTime() / 1000);

    if (props.showRecent) {
      let items: Capture.Info[] = [];
      groupedByHostCaptureList.forEach(([_, group]) => {
        items = [...items, ...group];
      });

      if (subscription === 'free' && currentUserInfo?.tier !== "1" && !organization) {
        items = items.filter(
          (item) => item.expiredAt && item.expiredAt > currentDateUnix
        );
      }
      // items = items.filter(capture => capture.hasOwnProperty('isDeleted') && !capture.isDeleted)

      return items;
    }



    const projects = groupedByHostCaptureList.map(([_, group]) => {
      let captures = [...group];
      if (subscription === 'free' && currentUserInfo?.tier !== "1" && !organization) {
        const currentDateUnix = Math.floor(new Date().getTime() / 1000);
        captures = captures.filter((item) => item.expiredAt && item.expiredAt > currentDateUnix);
      }
      return {
        gid: group[0].group,
        items: captures,
      };
    });

    if (props.isExpired) {
      const expiredCaptures = captureListArray.filter(
        (c) => c.expiredAt && c.expiredAt < currentDateUnix
      );
      setCurrentItems(expiredCaptures);
    }

    if (props.isDeleted) {
      setCurrentItems(capturesDeleted);
    }

    return (
      projects.find(
        (p) => p.gid === (currentProject.open ? currentProject.gid : null)
      )?.items ?? []
    );
  }, [
    groupedByHostCaptureList,
    props.showRecent,
    currentProject,
    subscription,
    capturesDeleted,
  ]);

  useEffect(() => {
    if (sidBarScrollRef.current && addingNew) {
      sidBarScrollRef.current.scrollTo({ top: 0, behavior: 'smooth' });
      setAddingNew(false);
    }
  }, [addingNew]);
  // const currentUserInfo = useRecoilValue(currentUserInfoState) as User;
  const isNameUpdatingValue = useRecoilValue(isNameUpdating);
  useEffect(() => { }, [isNameUpdatingValue]);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + 8;
    let captureLs = uniqBy(captureList, 'cid');

    if (subscription === 'free' && currentUserInfo?.tier !== "1" && !organization ) {
      captureLs.filter(
        (capture) =>
          capture.expiredAt &&
          capture.expiredAt > Math.floor(new Date().getTime() / 1000)
      );
    }

    captureLs = captureLs.filter(capture => capture.hasOwnProperty('isDeleted') && !capture.isDeleted)

    if (props.showRecent) {
      setCurrentItems(
        [...captureLs].sort((a, b) => b.createdAt - a.createdAt).slice(0, 4)
      );
      return;
    }
    setPageCount(Math.ceil(captureLs.length / 8));
    if (props.isExpired || props.isDeleted) return;
    setCurrentItems(captureLs.slice(itemOffset, endOffset));
  }, [itemOffset, groupedByHostCaptureList, props.showRecent, captureList]);

  // handle Click pagination
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * 8) % groupedByHostCaptureList.length;
    setItemOffset(newOffset);
  };

  return (
    <div className="w-full">
      {currentItems.length > 0  ? (
        <div className="grid grid-cols-4 gap-6 md:grid-cols-2 lg:grid-cols-3 lg:gap-4">
          {currentItems &&
            currentItems.map((item: any, index: number) => {
              return (
                <CaptureItemDashboard
                  disabled={isExpired && subscription === 'free'}
                  isDeleted={isDeleted}
                  isExpired={isExpired}
                  key={index}
                  index={index}
                  info={item}
                  url={item?.url || ''}
                />
              );
            })}
        </div>
      ) : (
        <div>
          <div className="flex">
            <div className="mx-auto empty-annotation-txt pt-6">
              <img src={EmptyProject} alt="empty-project" />
            </div>
          </div>
          <div className="flex">
            <div className="mx-auto empty-annotation-txt pb-8 pt-5">
              {props.sharedWithMe
                ? 'There are no Captures shared with you.'
                : 'There are no Captures yet.'}
            </div>
          </div>
        </div>
      )}
      {!props.hidePagination && currentItems.length > 0 && (
        <div className="flex justify-end mt-20">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            pageCount={pageCount}
            previousLabel="<"
            // renderOnZeroPageCount={null}
            activeClassName="active-page"
            className="flex justify-evenly parent-pagination px-2"
          />
        </div>
      )}
    </div>
  );
};

export default CaptureGrid;
